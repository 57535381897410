import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal, Skeleton, Box, Avatar, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupMembers } from '../../redux/universityslice';
import { useEffect, useState } from 'react';
import WalletIcon from '@mui/icons-material/Wallet';
import EmailIcon from '@mui/icons-material/Email';

export default function GroupMembersModel({ selectedGroup, open, handleClose }) {

    const dispatch = useDispatch();
    const { groupMembers, loading } = useSelector((state) => state.university);
    const [groupId, setGroupId] = useState(null);

    useEffect(() => {
        setGroupId(selectedGroup?._id);
        dispatch(getGroupMembers({ groupId: selectedGroup?._id }));
    }, [selectedGroup]);

    return (
        <div>
            <Modal
                aria-labelledby="user-details-modal-title"
                aria-describedby="user-details-modal-description"
                open={open}
                onClose={handleClose}
                slots={{ backdrop: StyledBackdrop }}
            >
                <ModalContent sx={{ width: 500, height: "70vh", overflowY: "scroll" }}>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'grey.500',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 3 }}>
                        <Typography variant="subtitle1" sx={{ color: 'gray', fontSize: '16px' }}>Groups Member:</Typography>
                        {groupMembers && groupMembers.length > 0 ? (
                            groupMembers.map((user) => (
                                <Box
                                    sx={{
                                        gap: 2,
                                        padding: 3,
                                        backgroundColor: 'background.paper',
                                        borderRadius: 2,
                                        boxShadow: 3
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: "row",
                                            alignItems: 'center',
                                            gap: 2
                                        }}
                                    >
                                        <Avatar
                                            alt={user?.name}
                                            src={user?.profileUrl}
                                            sx={{ width: 60, height: 60, marginY: 2 }}
                                        />

                                        <Typography variant="h5" component="h2">
                                            {user?.name} {user?.surname}
                                        </Typography>

                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <EmailIcon color="action" />
                                        <Typography variant="body1" sx={{ color: 'gray', fontSize: '16px' }}>Email:</Typography>
                                        <Typography variant="body1" sx={{ color: 'white', fontSize: '16px' }}>{user?.email}</Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <WalletIcon color="action" />
                                        <Typography variant="body1" sx={{ color: 'gray', fontSize: '16px' }}>Wallet:</Typography>
                                        <Typography variant="body1" sx={{ color: 'white', fontSize: '16px' }}>{user?.wallet?.$numberDecimal}</Typography>
                                    </Box>

                                </Box>
                            ))
                        ) : (
                            <Typography variant="body2" sx={{ color: 'gray', fontSize: '14px' }}>The user didn't join any course</Typography>
                        )}
                    </Box>
                </ModalContent>
            </Modal>
        </div >
    );
}

GroupMembersModel.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedGroup: PropTypes.object.isRequired,
};

const grey = {
    50: '#F3F6F9',
    900: '#191E23',
};

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'base-Backdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
    ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
    box-shadow: 0 4px 12px ${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.2)'};
  `
);
