import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal, Skeleton, Box, Avatar, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetailsById } from '../../redux/userslice';
import { useEffect, useState } from 'react';
import WalletIcon from '@mui/icons-material/Wallet';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

export default function UserDetailsModal({ selectedUser, open, handleClose }) {

    const dispatch = useDispatch();
    const { userDetails, userDetailsLoading } = useSelector((state) => state.users);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        setUserId(selectedUser?._id);
        if (selectedUser?._id) {
            dispatch(getUserDetailsById({ userId :selectedUser?._id }));
        }
    }, [selectedUser]);

    return (
        <div>
            <Modal
                aria-labelledby="user-details-modal-title"
                aria-describedby="user-details-modal-description"
                open={open}
                onClose={handleClose}
                slots={{ backdrop: StyledBackdrop }}
            >
                <ModalContent sx={{ width: 500 }}>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'grey.500',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {userDetailsLoading ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                            <Skeleton variant="circular" width={80} height={80} />
                            <Skeleton variant="text" width="60%" height={30} />
                            <Skeleton variant="text" width="80%" />
                            <Skeleton variant="text" width="40%" />
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', p: 2, gap: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                <Avatar
                                    alt={userDetails?.name}
                                    src={userDetails?.profileUrl}
                                    sx={{ width: 80, height: 80, marginY: 2 }}
                                />
                                <Typography variant="h4" component="h2">
                                    {userDetails?.name} {userDetails?.surname}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <EmailIcon color="action" />
                                <Typography variant="body1" sx={{ color: 'gray', fontSize: '16px' }}>Email:</Typography>
                                <Typography variant="body1" sx={{ color: 'white', fontSize: '16px' }}>{userDetails?.email}</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <LanguageIcon color="action" />
                                <Typography variant="body1" sx={{ color: 'gray', fontSize: '16px' }}>Favorite Language:</Typography>
                                <Typography variant="body1" sx={{ color: 'white', fontSize: '16px' }}>{userDetails?.favoriteLanguage}</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <ContactPhoneIcon color="action" />
                                <Typography variant="body1" sx={{ color: 'gray', fontSize: '16px' }}>Phone Number:</Typography>
                                <Typography variant="body1" sx={{ color: 'white', fontSize: '16px' }}>{userDetails?.phoneNumber}</Typography>
                            </Box>


                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant="subtitle1" sx={{ color: 'gray', fontSize: '16px' }}>Groups:</Typography>                                {userDetails?.nonActiveGroups && userDetails.nonActiveGroups.length > 0 ? (
                                    userDetails.activeGroups.map((group) => (
                                        <Box key={group._id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <GroupIcon color="action" />
                                            <Typography variant="body2" sx={{ color: 'white', fontSize: '14px' }}>{group.name}</Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography variant="body2" sx={{ color: 'gray', fontSize: '14px' }}>The user didn't join any course</Typography>
                                )}
                            </Box>



                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant="subtitle1" sx={{ color: 'gray', fontSize: '16px' }}>Inactive Groups:</Typography>                                {userDetails?.nonActiveGroups && userDetails.nonActiveGroups.length > 0 ? (
                                    userDetails.nonActiveGroups.map((group) => (
                                        <Box key={group._id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <GroupIcon color="action" />
                                            <Typography variant="body2" sx={{ color: 'white', fontSize: '14px' }}>{group.name}</Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography variant="body2" sx={{ color: 'gray', fontSize: '14px' }}>The user didn't join any course</Typography>
                                )}
                            </Box>


                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <WalletIcon color="action" />
                                <Typography variant="body1" sx={{ color: 'gray', fontSize: '16px' }}>Wallet:</Typography>
                                <Typography variant="body1" sx={{ color: 'white', fontSize: '16px' }}>{userDetails?.wallet?.$numberDecimal}</Typography>
                            </Box>
                        </Box>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}

UserDetailsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedUser: PropTypes.object.isRequired,
};

// Styling for Modal components
const grey = {
    50: '#F3F6F9',
    900: '#191E23',
};

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'base-Backdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
    ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
    box-shadow: 0 4px 12px ${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.2)'};
  `
);
