import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from './ApiService';

const apiService = new ApiService();

const initialState = {

    courses: [],
    loading: false,
    totalRecords: 0,
    coursesError: null,
    categories: [],
    categoriesError: null,
    addNewCategorySuccessfully: null,
    addNewCategoryError: null,
    editCategorySuccessfully: null,
    editCategoryError: null,
    deleteCategorySuccessfully: null,
    deleteCategoryError: null,
    chapters: [],
    chaptersError: null,
    chaptersLoading: null,
    classes: [],
    classesError: null,
    addNewCourseSuccessfully: null,
    addNewCourseError: null,
    editCourseSuccessfully: null,
    editCourseError: null,
    deleteCourseSuccessfully: null,
    deleteCourseError: null,
    addNewChapterSuccessfully: null,
    addNewChapterError: null,
    editChapterSuccessfully: null,
    editChapterError: null,
    addNewClassSuccessfully: null,
    addNewClassError: null,
    deleteChapterSuccessfully: null,
    deleteChapterError: null,
    deleteClassSuccessfully: null,
    deleteClassError: null,
    editClassSuccessfully: null,
    editClassError: null,
};


export const coursesList = createAsyncThunk(
    'i-course/courses',
    async ({ selectedCategory }, { rejectWithValue }) => {
        try {
            console.log("here in the api ");
            const response = await apiService.get(`/i-courses/courses?category-id=${selectedCategory}`);
            return response.data;

        } catch (error) {

            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


export const categoryList = createAsyncThunk(
    'i-course/new-category',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService.get('/i-courses/categories');
            return response.data;

        } catch (error) {

            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


export const addNewCategory = createAsyncThunk(
    'i-course/category',
    async ({ title, titleInArabic, photo }, { rejectWithValue }) => {
        try {

            const formData = new FormData();
            formData.append('title', title);
            formData.append('titleInArabic', titleInArabic);
            formData.append('icon', photo);
            const response = await apiService.postFormData('/i-courses/category', formData);
            return response.data;

        } catch (error) {
            console.log("error", error.message);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const EditCategory = createAsyncThunk(
    'i-course/edit-category',
    async ({ categoryId, title, titleInArabic, photo }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('id', categoryId);
            formData.append('title', title);
            formData.append('titleInArabic', titleInArabic);

            if (photo) {
                formData.append('icon', photo);
            }

            const response = await apiService.putFormData('/i-courses/category', formData);
            return response.data;
        } catch (error) {
            console.log("error", error.message);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const DeleteCategory = createAsyncThunk(
    'i-course/delete-category',
    async ({ categoryId }, { rejectWithValue }) => {
        try {
            const response = await apiService.delete(`/i-courses/category?categoryId=${categoryId}`);
            return response.data;

        } catch (error) {
            console.log("error", error.message);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


export const chaptersList = createAsyncThunk(
    'i-course/chapters',
    async ({ selectedCourseId }, { rejectWithValue }) => {
        try {
            const response = await apiService.get(`/i-courses/chapters?courseId=${selectedCourseId}`);
            return response.data;

        } catch (error) {

            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const classesList = createAsyncThunk(
    'i-course/classes',
    async ({ selectedChapterId }, { rejectWithValue }) => {
        try {

            const response = await apiService.get(`/i-courses/classes?cahpterId=${selectedChapterId}`);
            return response.data;

        } catch (error) {

            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


export const addNewCourse = createAsyncThunk(
    'i-course/add',
    async ({ categoryId, title, type, price, description, status, cover }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("categoryId", categoryId)
            formData.append('title', title);
            formData.append('type', type);
            formData.append('price', price);
            formData.append('descriptions', description);
            formData.append('status', status);
            formData.append('cover', cover);
            const response = await apiService.postFormData('/i-courses/course', formData);
            return response.data;
        } catch (error) {

            console.log("error", error.message);
            console.log("error.message", error)
            return rejectWithValue(error.response?.data || { message: error.message });

        }
    }
);


export const editCourse = createAsyncThunk(
    'i-course/edit',
    async ({ courseId, categoryId, title, type, price, descriptions, status, cover }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("categoryId", categoryId)
            formData.append('title', title);
            formData.append('type', type);
            formData.append('price', price);
            formData.append('descriptions', descriptions);
            formData.append('status', status);
            formData.append('cover', cover);
            formData.append('courseId', courseId);
            const response = await apiService.putFormData('/i-courses/course', formData);
            return response.data;
        } catch (error) {
            console.log("error", error.message);
            console.log("error.message", error)
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    });



export const deleteCourse = createAsyncThunk(
    'i-course/delete',
    async ({ courseId }, { rejectWithValue }) => {
        try {
            const response = await apiService.delete(`/i-courses/course?courseId=${courseId}`);
            return response.data;
        } catch (error) {
            console.log("error", error.message);
            console.log("error.message", error)
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


export const addNewChapter = createAsyncThunk(
    'i-course/chapter/add',
    async ({ courseId, title }, { rejectWithValue }) => {
        try {
            const data = {
                "courseId": courseId,
                "title": title
            };
            const response = await apiService.post('/i-courses/create-chapter', data);
            return response.data;
        } catch (error) {

            console.log("error", error.message);
            console.log("error.message", error)
            return rejectWithValue(error.response?.data || { message: error.message });

        }
    }
);

export const editChapter = createAsyncThunk(
    'i-course/chapter/edit',
    async ({ chapterId, title }, { rejectWithValue }) => {
        try {
            const data = {
                "chapterId": chapterId,
                "title": title
            };
            const response = await apiService.put('/i-courses/edit-chapter', data);
            return response.data;
        } catch (error) {

            console.log("error", error.message);
            console.log("error.message", error)
            return rejectWithValue(error.response?.data || { message: error.message });

        }
    }
);


export const deleteChapter = createAsyncThunk(
    'i-course/chapter/delete',
    async ({ chapterId }, { rejectWithValue }) => {
        try {
            const response = await apiService.delete(`/i-courses/chapter?chapterId=${chapterId}`);
            return response.data;
        } catch (error) {
            console.log("error", error.message);
            console.log("error.message", error)
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);




export const addNewClass = createAsyncThunk(
    'i-course/classes/add',
    async ({ chapterId, title, file }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("title", title)
            formData.append("chapterId", chapterId)
            file.forEach(subfile => {
                formData.append('file', subfile);
            });
            const response = await apiService.postFormData('/i-courses/create-class', formData);
            return response.data;
        } catch (error) {

            console.log("error", error.message);
            console.log("error.message", error)
            return rejectWithValue(error.response?.data || { message: error.message });

        }
    }
);


export const editClass = createAsyncThunk(
    'i-course/classes/edit',
    async ({ classId, title, file }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("title", title)
            formData.append("classId", classId)
            file.forEach(subfile => {
                formData.append('file', subfile);
            });
            const response = await apiService.postFormData('/i-courses/edit-class', formData);
            return response.data;
        } catch (error) {

            console.log("error", error.message);
            console.log("error.message", error)
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    });


export const deleteClass = createAsyncThunk(
    'i-course/classes/delete',
    async ({ classId }, { rejectWithValue }) => {
        try {
            const response = await apiService.delete(`/i-courses/class?classId=${classId}`);
            return response.data;
        } catch (error) {

            console.log("error", error.message);
            console.log("error.message", error)
            return rejectWithValue(error.response?.data || { message: error.message });

        }
    }
);




const coursesSlice = createSlice({
    name: 'icourse',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {

        builder
            .addCase(coursesList.pending, (state) => {
                state.loading = true;
                state.coursesError = null;
            })
            .addCase(coursesList.fulfilled, (state, action) => {
                state.loading = false;
                state.courses = action.payload.items.items;
                state.totalRecords = action.payload.links?.totalRecord || 0;
                state.coursesError = null;
                state.editCourseSuccessfully = null;
                state.editCourseError = null;
                state.deleteCourseSuccessfully = null;
                state.deleteCourseError = null;
            })
            .addCase(coursesList.rejected, (state, action) => {
                state.loading = false;
                state.coursesError = action.payload?.message || 'An error occurred';
            })
            .addCase(categoryList.pending, (state) => {
                state.loading = true;
                state.categoriesError = null;
            })
            .addCase(categoryList.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload.items;
                state.totalRecords = action.payload.items?.lenght || 0;
                state.addNewCategoryError = null;
                state.addNewCategorySuccessfully = null;
                state.editCategorySuccessfully = null;
                state.editCategoryError = null;
                state.deleteCategorySuccessfully = null;
                state.deleteCategoryError = null;
            })
            .addCase(categoryList.rejected, (state, action) => {
                state.loading = false;
                state.categoriesError = action.payload?.message || 'An error occurred';
            })
            .addCase(addNewCategory.pending, (state) => {
                state.loading = true;
                state.addNewCategoryError = null;
            })
            .addCase(addNewCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.addNewCategorySuccessfully = true;
            })
            .addCase(addNewCategory.rejected, (state, action) => {
                state.loading = false;
                state.addNewCategoryError = action.payload?.message || 'An error occurred';
            })
            .addCase(EditCategory.pending, (state) => {
                state.loading = true;
                state.editCategoryError = null;
                state.editCategorySuccessfully = null;
            })
            .addCase(EditCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.editCategorySuccessfully = true;
            })
            .addCase(EditCategory.rejected, (state, action) => {
                state.loading = false;
                console.log("action.payload", action.payload.message);
                state.editCategoryError = action.payload?.message || 'An error occurred';
            })
            .addCase(DeleteCategory.pending, (state) => {
                state.loading = true;
                state.deleteCategoryError = null;
            })
            .addCase(DeleteCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteCategorySuccessfully = true;
            })
            .addCase(DeleteCategory.rejected, (state, action) => {
                state.loading = false;
                state.deleteCategoryError = action.payload?.message || 'An error occurred';
            })
            .addCase(chaptersList.pending, (state) => {
                state.loading = true;
                state.chaptersError = null;
                state.chaptersLoading = true;
                state.addNewChapterSuccessfully = null;
                state.addNewChapterError = null;
                state.editChapterSuccessfully = null;
                state.editChapterError = null;
                state.deleteChapterSuccessfully = null;
                state.deleteChapterError = null;
                state.deleteClassSuccessfully = null;
                state.deleteClassError = null;
            })
            .addCase(chaptersList.fulfilled, (state, action) => {
                state.loading = false;
                console.log("action.payload.items chaptersList", action.payload.items);
                state.chapters = action.payload.items;
            })
            .addCase(chaptersList.rejected, (state, action) => {
                state.loading = false;
                state.chaptersError = action.payload?.message || 'An error occurred';

            })
            .addCase(classesList.pending, (state) => {
                state.loading = true;
                state.classesError = null;
                state.addNewClassSuccessfully = null;
                state.addNewClassError = null;
            })
            .addCase(classesList.fulfilled, (state, action) => {
                state.loading = false;
                state.classes = action.payload.items;
            })
            .addCase(classesList.rejected, (state, action) => {
                state.loading = false;
                state.classesError = action.payload?.message || 'An error occurred';
            })
            .addCase(addNewCourse.pending, (state) => {
                state.loading = true;
                state.addNewCourseError = null;
                state.addNewCourseSuccessfully = null;
            })
            .addCase(addNewCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.addNewCourseSuccessfully = true;
                state.addNewCourseError = false;
            })
            .addCase(addNewCourse.rejected, (state, action) => {
                state.loading = false;
                state.addNewCourseError = true;
                state.addNewCourseSuccessfully = false;
            })
            .addCase(editCourse.pending, (state) => {
                state.loading = true;
                state.editCourseError = null;
                state.editCourseSuccessfully = null;
            })
            .addCase(editCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.editCourseSuccessfully = true;
                state.editCourseError = false;
            })
            .addCase(editCourse.rejected, (state, action) => {
                state.loading = false;
                state.editCourseError = true;
                state.editCourseSuccessfully = false;
            })
            .addCase(deleteCourse.pending, (state) => {
                state.loading = true;
                state.deleteCourseError = null;
                state.deleteCourseSuccessfully = null;
            })
            .addCase(deleteCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteCourseSuccessfully = true;
                state.deleteCourseError = false;
            })
            .addCase(deleteCourse.rejected, (state, action) => {
                state.loading = false;
                state.deleteCourseError = true;
                state.deleteCourseSuccessfully = false;
            })


            .addCase(addNewChapter.pending, (state) => {
                state.loading = true;
                state.addNewChapterError = null;
                state.addNewChapterSuccessfully = null;
            })
            .addCase(addNewChapter.fulfilled, (state, action) => {
                state.loading = false;
                state.addNewChapterSuccessfully = true;
                state.addNewChapterError = false;
            })
            .addCase(addNewChapter.rejected, (state, action) => {
                state.loading = false;
                state.addNewChapterError = true;
                state.addNewChapterSuccessfully = false;
            })

            .addCase(editChapter.pending, (state) => {
                state.loading = true;
                state.editChapterError = null;
                state.editChapterSuccessfully = null;
            })
            .addCase(editChapter.fulfilled, (state, action) => {
                state.loading = false;
                state.editChapterSuccessfully = true;
                state.editChapterError = false;
            })
            .addCase(editChapter.rejected, (state, action) => {
                state.loading = false;
                state.editChapterError = true;
                state.editChapterSuccessfully = false;
            })
            .addCase(deleteChapter.pending, (state) => {
                state.loading = true;
                state.deleteChapterError = null;
                state.deleteChapterSuccessfully = null;
            })
            .addCase(deleteChapter.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteChapterSuccessfully = true;
                state.deleteChapterError = false;
            })
            .addCase(deleteChapter.rejected, (state, action) => {
                state.loading = false;
                state.deleteChapterError = true;
                state.deleteChapterSuccessfully = false;
            })
            .addCase(addNewClass.pending, (state) => {
                state.loading = true;
                state.addNewClassError = null;
                state.addNewClassSuccessfully = null;
            })
            .addCase(addNewClass.fulfilled, (state, action) => {
                state.loading = false;
                state.addNewClassSuccessfully = true;
                state.addNewClassError = false;
            })
            .addCase(addNewClass.rejected, (state, action) => {
                state.loading = false;
                state.addNewClassError = true;
                state.addNewClassSuccessfully = false;
            })
            .addCase(deleteClass.pending, (state) => {
                state.loading = true;
                state.deleteClassError = null;
                state.deleteClassSuccessfully = null;
            })
            .addCase(deleteClass.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteClassSuccessfully = true;
                state.deleteClassError = false;
            })
            .addCase(deleteClass.rejected, (state, action) => {
                state.loading = false;
                state.deleteClassError = true;
                state.deleteClassSuccessfully = false;
            })
            .addCase(editClass.pending, (state) => {
                state.loading = true;
                state.editClassError = null;
                state.editClassSuccessfully = null;
            })
            .addCase(editClass.fulfilled, (state, action) => {
                state.loading = false;
                state.editClassSuccessfully = true;
                state.editClassError = false;
            })
            .addCase(editClass.rejected, (state, action) => {
                state.loading = false;
                state.editClassError = true;
                state.editClassSuccessfully = false;
            })

    },
});


export default coursesSlice.reducer;
