import { Box, IconButton, Button } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import AddNewChapterModel from "../../components/i-course/chapter/AddNewChapter";
import EditChapterModel from "../../components/i-course/chapter/EditChapter";
import DeleteChapterModal from "../../components/i-course/chapter/DeleteChapter";
import { coursesList, categoryList, chaptersList } from "../../redux/icourseslice";
import SelectComponent from "../../components/Select";

const IcourseChapters = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const [anchorEl, setAnchorEl] = useState(null);
  const { loading, categories, totalRecords, courses, chapters, chaptersLoading, addNewChapterSuccessfully,  editChapterSuccessfully,  deleteChapterSuccessfully } = useSelector((state) => state.icourse);

  const [selectedChapter, setSelectedChapter] = useState(null);
  const [openNewChapter, setOpenNewChapter] = useState(false);
  const [openEditChapter, setOpenEditChapter] = useState(false);
  const [openDeleteUnivesity, setOpenDeleteUnivesity] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    document.title = "I Course - Chapters | Admin Panel";
    dispatch(categoryList());
    setPaginationModel({
      pageSize: courses?.length || 10,
      page: 0,
    });

  }, [])

  const handleCategoryChange = (event) => {
    const selectedUniId = event.target.value;
    setSelectedCategory(selectedUniId);
    dispatch(coursesList({ selectedCategory: selectedUniId }));
  };


  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    setSelectedCourse(courseId);
    dispatch(chaptersList({ selectedCourseId:courseId }));
  }




  const categoriesOptions = (categories || []).map((category) => ({
    value: category?._id,
    label: category?.title,
  }));


  const categoriesCourses = (courses || []).map((course) => ({
    value: course?._id,
    label: course?.title,
  }));

  const handleMenuClick = (event, chapter) => {
    setSelectedChapter(null);
    setAnchorEl(event.currentTarget);
    setSelectedChapter(chapter);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNewChapter = () => {
    setOpenNewChapter(false);
  }

  const handleOpenNewChapter = () => {
    setOpenNewChapter(true);
  }

  const handleOpenModalEditChapter = () => {
    setOpenEditChapter(true);
    handleMenuClose()
  }

  const handleCloseModalEditChapter = () => {
    setOpenEditChapter(false);
  }



  const handleCloseModalDeleteUnivesity = () => {
    setOpenDeleteUnivesity(false);

  }

  const handleOpenModalDeleteUnivesity = () => {
    setOpenDeleteUnivesity(true);
    handleMenuClose()
  }

  useEffect(() => {

    if (selectedCourse && (addNewChapterSuccessfully === true || editChapterSuccessfully ===true || deleteChapterSuccessfully === true)) {
      dispatch(chaptersList({selectedCourseId: selectedCourse }));
    }

  }, [addNewChapterSuccessfully,  editChapterSuccessfully, deleteChapterSuccessfully])


  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "title",
      headerName: "title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => {
            event.stopPropagation();
            handleMenuClick(event, params.row)
          }}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={() => { handleOpenModalEditChapter() }} >Edit</MenuItem>
            <MenuItem onClick={() => handleOpenModalDeleteUnivesity()} >Delete</MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="I-Course - Chapters"
        subtitle="Managing the Chapters  of I-Course"
      />

      <AddNewChapterModel categoriesOptions={categoriesOptions} open={openNewChapter} handleClose={handleCloseNewChapter} />
      <EditChapterModel  selectedChapter={selectedChapter} open={openEditChapter} handleClose={handleCloseModalEditChapter} />
      <DeleteChapterModal selsctedChapter={selectedChapter} open={openDeleteUnivesity} handleClose={handleCloseModalDeleteUnivesity} />

      <Box display="flex" flexDirection="row" gap={2} sx={{ width: '100%' }}>


        <Button
          variant="contained"
          sx={{ backgroundColor: '#0093e6', color: '#fff' }}
          onClick={() => handleOpenNewChapter()}
        >
          Add New Chapter
        </Button>

        <SelectComponent
          label="Select a Category"
          width="25%"
          value={selectedCategory}
          onChange={handleCategoryChange}
          options={categoriesOptions}
        />

        <SelectComponent
          label="Select a Course"
          width="25%"
          value={selectedCourse}
          onChange={handleCourseChange}
          options={categoriesCourses}
        />

      </Box>




      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >


        <DataGrid
          loading={loading}
          checkboxSelection
          columns={columns}
          rows={chapters || []}
          getRowId={(row) => row._id}
        />




      </Box>
    </Box>
  );
};

export default IcourseChapters;